<template>
  <div v-if="metricsData" ref="metricsSection" :class="`w-full py-[40px] ${bgOverlay}`">
    <h2 v-if="metricsData.title" class="font-normal text-[#151313] text-[25px] text-center md:text-start">
      {{ metricsData.title }}
    </h2>
    <hr v-if="metricsData.title" class="titleSeparator">
    <div
      :class="`flex flex-col md:flex-row items-center gap-[80px] ${metricsData.title ? 'justify-between' : 'justify-around'}`"
    >
      <div v-for="(metric, index) in metricsData.metrics" :key="'paragraph-' + index" class="flex items-center gap-[30px] flex-col">
        <div class="text-[#E54825] text-[50px] font-bold">
          {{ displayValues[index] }}{{ metric.unit }}
        </div>
        <div :class="`${bgOverlay ? 'text-white' : 'text-black'}`">
          {{ metric.key }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  metricsData: {
    type: Object,
    default: () => null
  },
  bgOverlay: {
    type: String,
    default: ''
  }
})

const displayValues = ref([])
const metricsSection = ref(null)
const hasAnimated = ref(false)

const countUp = (start, end, index) => {
  let startTimestamp = null
  const duration = 2000
  const step = (timestamp) => {
    if (!startTimestamp) { startTimestamp = timestamp }
    const progress = Math.min((timestamp - startTimestamp) / duration, 1)
    displayValues.value[index] = Math.floor(progress * (end - start) + start)
    if (progress < 1) {
      window.requestAnimationFrame(step)
    }
  }
  window.requestAnimationFrame(step)
}

const startCounting = () => {
  props.metricsData.metrics.forEach((metric, index) => {
    countUp(0, metric.value, index)
  })
}

useIntersectionObserver(
  metricsSection,
  ([{ isIntersecting }]) => {
    if (isIntersecting && !hasAnimated.value) {
      startCounting()
      hasAnimated.value = true
    }
  },
  {
    threshold: 0.1
  }
)
</script>

<style>
.titleSeparator {
  margin-top: 12px;
  margin-bottom: 50px;
  width: 100%;
  height: 100%;
  border: 1px rgba(21, 19, 19, 0.30) solid;
}
</style>
